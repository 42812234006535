<template>
  <div class="app-contianer">
    <div class="poster-box page-item">
      <img class="leftImg" src="../../assets/team/R@2x.png" alt="">
      <div class="info white">
        <p class="f45"><strong>愿与您合作共赢</strong></p>
        <p class="margin-t2 f38">共创未来无限可能</p>
      </div>
    </div>

    <div class="page-item">
      <div class="title f38">
        <strong>团长收益</strong>
        <div class="fr f34 grey" @click="goOrderDesc()">查看明细<i class="el-icon-arrow-right"></i></div>
      </div>

      <div class="content flex f38">
        <p><span class="grey6">累计收益:</span> <strong>{{teamProfit}}</strong></p>
        <p class="margin-lr2"><span class="grey6">累计团员:</span> <strong>{{teamNum}}</strong></p>
      </div>
    </div>

    <div class="page-item">
      <div class="title f38"><strong>团长奖励规则</strong></div>
      <div class="margin-t2">
        <div class="active-text" v-html="teamRule" style="white-space: pre-wrap;"></div>
      </div>
    </div>

    <div class="submitBut f40" @click="goPage('/ticky')">立即生成海报</div>

  </div>
</template>

<script>
import { mallApi } from '@/api'
import { mapState } from 'vuex'


export default {
  name: 'team',
  data() {
    return {
      teamNum: 0,
      teamProfit: 0,
      teamRule: '平台未设置, 详情请联系管理员~'
    }
  },
  created() {
    this.getTeamOrderList()
    if (this.agentConfig.TEAM_RULE_WORD) {
      this.teamRule = this.agentConfig.TEAM_RULE_WORD
    }
  },
  computed: {
    ...mapState('user', ['agentConfig']),
  },
  methods: {
    getTeamOrderList() {
      let params = {
        trxCode: 'XF917',
        type: 'time',
        page: 1,
        pageSize: 10
      }
      mallApi(params).then(res => {
        this.teamProfit = res.teamProfit
        this.teamNum = res.first + res.second
      })
    },

    goOrderDesc() {
      this.$router.push({ path: '/ourteam', query: { listType: 'order' } })
    },
    // 页面跳转
    goPage(path) {
      this.$router.push({ path: path })
    },
  }
}
</script>

<style lang='less' scoped>
.poster-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 24vw;
  padding: 0 10vw 0 6vw;
  background-image: url('../../assets/team/poster_1.png');
  background-size: 100%;
  background-repeat: no-repeat;
}
.leftImg {
  width: 28vw;
  height: 24vw;
}
.content {
  margin-top: 4vw;
  justify-content: space-between;
}
</style>